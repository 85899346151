<template>
  <div>
    <!-- <el-row class="myrow">
      <el-col :span="12" :offset="6">
        <quill-editor 
          v-model="content"
          ref="myQuillEditor"
          :options="editorOption">
        </quill-editor>
        <el-button @click="getVal" class="button">get</el-button>
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
	var self;

  // require styles
  // import 'quill/dist/quill.core.css'
  // import 'quill/dist/quill.snow.css'
  // import 'quill/dist/quill.bubble.css'

  // import { quillEditor } from 'vue-quill-editor'

  export default {
  	activated(){
  		self = this;
  	},
    data() {
      return {
        content: '<h2>I am Example</h2>',
        editorOption: {
          theme: "snow"
        }
      };
    },
    methods: {
      getVal(){
        console.log(self.content);
      }
    },
    components: {
      quillEditor
    }
  }
</script>

<style lang="scss" scoped>
  .button{
    margin-top: 10px;
  }
  .myrow{
    margin-top: 20px;
  }
</style>
